import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import { Container, Text, Box } from 'theme-ui'
import Layout from '../components/Layout'
import Markdown from '../components/markdown'
import MailchimpHtml from '../components/mailchimp-html/MailchimpHtml.jsx'

export const AboutPageTemplate = ({ title, content }) => {
  return (
    <Container mt={[4, 4, 4, 5, 5]} mb={[4, 4, 4, 5, 5]}>
      <Box my={[4, 4, 4, 5, 5]}>
        {title && (
          <Text as="h1" sx={{ variant: 'styles.h1', mt: 4 }}>
            {title}
          </Text>
        )}
        {content && <Markdown content={content} pmb={[2, 2, 2, 3, 3]} />}
      </Box>
      <Box>
        <MailchimpHtml />
      </Box>
    </Container>
  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
}

const AboutPage = ({
  data: {
    markdownRemark: { frontmatter: data },
  },
}) => {
  return (
    <Layout>
      <AboutPageTemplate {...data} />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        data: PropTypes.shape({}),
      }),
    }).isRequired,
  }).isRequired,
}

export default () => (
  <StaticQuery
    query={graphql`
      query AboutPageQuery {
        markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {
          frontmatter {
            title
            content
          }
        }
      }
    `}
    render={(data) => <AboutPage data={data} />}
  />
)
