import React from 'react'

const dirtyHtml = `<link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" 
rel="stylesheet" type="text/css">
<style type="text/css">
#mc_embed_signup .mc-field-group.input-group input{
     margin-right: 4px;
   }

   #mc_embed_signup .mc-field-group.input-group input:checked{
     background-color: black;
   }

   #mc_embed_signup .button{
     background-color: white;
     color: black;
     border: 1px solid black;
   }
</style>
<div id="mc_embed_signup">
<form 
action="https://i-e-f.us10.list-manage.com/subscribe/post?u=eada80770ae64e234884552db&amp;id=a1a1c2a0be" 
method="post" id="mc-embedded-subscribe-form" 
name="mc-embedded-subscribe-form" class="validate" target="_blank" 
novalidate>
     <div id="mc_embed_signup_scroll">
     <h2>Subscribe</h2>
<div class="indicates-required"><span class="asterisk">*</span> 
indicates required</div>
<div class="mc-field-group">
     <label for="mce-EMAIL">Email Address  <span class="asterisk">*</span>
</label>
     <input type="email" value="" name="EMAIL" class="required email" 
id="mce-EMAIL">
</div>
<div class="mc-field-group">
     <label for="mce-FNAME">First Name </label>
     <input type="text" value="" name="FNAME" class="" id="mce-FNAME">
</div>
<div class="mc-field-group">
     <label for="mce-LNAME">Last Name </label>
     <input type="text" value="" name="LNAME" class="" id="mce-LNAME">
</div>
<div class="mc-field-group">
     <label for="mce-MMERGE3">City </label>
     <input type="text" value="" name="MMERGE3" class="" id="mce-MMERGE3">
</div>
<div class="mc-field-group">
     <label for="mce-MMERGE4">Country </label>
     <input type="text" value="" name="MMERGE4" class="" id="mce-MMERGE4">
</div>
<div class="mc-field-group input-group">
     <strong>Sector </strong>
     <ul><li><input type="checkbox" value="1" name="group[31845][1]" 
id="mce-group[31845]-31845-0"><label 
for="mce-group[31845]-31845-0">Artist</label></li>
<li><input type="checkbox" value="2" name="group[31845][2]" 
id="mce-group[31845]-31845-1"><label 
for="mce-group[31845]-31845-1">Agent</label></li>
<li><input type="checkbox" value="4" name="group[31845][4]" 
id="mce-group[31845]-31845-2"><label for="mce-group[31845]-31845-2">Club 
Promoter</label></li>
<li><input type="checkbox" value="8" name="group[31845][8]" 
id="mce-group[31845]-31845-3"><label 
for="mce-group[31845]-31845-3">Festival Promoter</label></li>
<li><input type="checkbox" value="16" name="group[31845][16]" 
id="mce-group[31845]-31845-4"><label 
for="mce-group[31845]-31845-4">Venue</label></li>
<li><input type="checkbox" value="32" name="group[31845][32]" 
id="mce-group[31845]-31845-5"><label 
for="mce-group[31845]-31845-5">Record Label</label></li>
<li><input type="checkbox" value="64" name="group[31845][64]" 
id="mce-group[31845]-31845-6"><label 
for="mce-group[31845]-31845-6">Marketing/PR</label></li>
<li><input type="checkbox" value="128" name="group[31845][128]" 
id="mce-group[31845]-31845-7"><label 
for="mce-group[31845]-31845-7">Media</label></li>
<li><input type="checkbox" value="256" name="group[31845][256]" 
id="mce-group[31845]-31845-8"><label 
for="mce-group[31845]-31845-8">Production Services</label></li>
<li><input type="checkbox" value="512" name="group[31845][512]" 
id="mce-group[31845]-31845-9"><label 
for="mce-group[31845]-31845-9">Other</label></li>
</ul>
</div>
     <div id="mce-responses" class="clear">
         <div class="response" id="mce-error-response" 
style="display:none"></div>
         <div class="response" id="mce-success-response" 
style="display:none"></div>
     </div>    <!-- real people should not fill this in and expect good 
things - do not remove this or risk form bot signups-->
     <div style="position: absolute; left: -5000px;" 
aria-hidden="true"><input type="text" 
name="b_eada80770ae64e234884552db_a1a1c2a0be" tabindex="-1" value=""></div>
     <div class="clear"><input type="submit" value="Subscribe" 
name="subscribe" id="mc-embedded-subscribe" class="button"></div>
     </div>
</form>
</div>
<script type='text/javascript' 
src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script><script 
type='text/javascript'>(function($) {window.fnames = new Array(); 
window.ftypes = new 
Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='MMERGE3';ftypes[3]='text';fnames[4]='MMERGE4';ftypes[4]='text';}(jQuery));var 
$mcj = jQuery.noConflict(true);</script>`

const MailchimpHtml = () => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: dirtyHtml,
      }}
    />
  )
}

export default MailchimpHtml
